<template lang="pug">
#ready-to-start.interrupt-step-component
  icon.rts-icon(data="@icon/turn-up-volume.svg")

  h1 You are now ready to start your exam!

  p Please play the audio track below and adjust your volume settings.
  audio(ref="testAudio", @play="onPlay", controls, loop, src="~@/assets/audio/volume-test.mp3")

  button.button(@click="gotoNext()", :disabled="!hasTestedAudio") {{ buttonText }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {},

  data() {
    return {
      hasTestedAudio: false,
    }
  },

  computed: {
    buttonText,
    ...mapState({
      localAudioTrack: state => state.twilio.localAudioTrack,
    }),
    ...mapGetters({
      isIOS15: 'user/getIsIOS15',
      proceededWithoutVideo: 'user/getVideoPermission',
    }),
  },

  created() {
    this.$app.forceLandscape = true
  },

  watch: {},

  methods: {
    gotoNext,
    onPlay,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function buttonText() {
  return this.hasTestedAudio
    ? "I can hear the audio.  Let's get started!"
    : 'Play audio to continue'
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function onPlay() {
  this.hasTestedAudio = true

  if (this.isIOS15 && !this.proceededWithoutVideo) {
    this.localAudioTrack.restart()
  }
}

function gotoNext() {
  this.$refs.testAudio?.pause()
  this.$emit('complete')
}
</script>
